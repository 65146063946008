import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AssistantService } from "shared/services/AssistantService";
import {
  AssistantSessionConfig,
  AssistantTaskUpdate,
} from "types/assistant/AssistantTypes";
import useThreadsStore from "shared/store/threadsStore";
import SocketService from "shared/services/ws/SocketService";
import { AI_PRO_ADVISOR } from "constants/AiProAdvisor";
import EsgFormData from "config/esg-form-data.json";
import { ChatMain } from "./ChatMain";
import { ChatWelcome } from "./components/ChatWelcome";
import "./styles.css";
import activityPanelStore from "shared/store/activityPanelStore";
import appStatusStore from "shared/store/appStatusStore";
import { extractPromptContent, startsWithPrompt } from "utils/promptUtils";
import { MessageData } from "./components/ChatInput";

export type InitialMessage = {
  text: string;
  vectorStoreIds: string[];
};
type ChatPanelProps = {
  isWidget?: boolean;
  proposalId?: string;
  widgetThreadId?: string;
};

export const ChatPanel: React.FC<ChatPanelProps> = ({
  isWidget = false,
  proposalId = null,
  widgetThreadId,
}) => {
  const fromHistory = useThreadsStore((state) => state.fromHistory);
  const appendMessages = useThreadsStore((state) => state.appendMessages);
  const appendMessageDelta = useThreadsStore((state) => state.appendMessageDelta);
  const appendAttachments = useThreadsStore((state) => state.appendAttachments);
  const appendAction = useThreadsStore((state) => state.appendAction);
  const resetAction = useThreadsStore((state) => state.resetAction);
  const setRefreshProposalId = useThreadsStore(
    (state) => state.setRefreshProposalId,
  );
  const setAgentStatus = appStatusStore((state) => state.setAgentStatus);

  const setHasSentMessage = activityPanelStore(
    (state) => state.setHasSentMessage,
  );
  // const setHasUserScrolled = useThreadsStore(
  //   (state) => state.setHasUserScrolled,
  // );

  const { setSocketServiceRef } = activityPanelStore();

  const navigate = useNavigate();

  let threadId: string;
  const params = useParams();
  if (!isWidget) {
    threadId = params.threadId;
  } else {
    threadId = widgetThreadId;
  }

  // const [threadId, setThreadId] = useState(params.threadId);

  // useEffect(() => {
  //   if (widgetThreadId && isWidget) {
  //     setThreadId(widgetThreadId);
  //   }
  // }, [widgetThreadId, isWidget]);

  const [thinking, setThinking] = useState(false);
  const [status, setStatus] = useState("");
  const [isConnectionOpen, setIsConnectionOpen] = useState(false);
  const [requestExternalPrompt, setRequestExternalPrompt] = useState(null);

  const socketServiceRef = useRef<SocketService | null>(null);

  useEffect(() => {
    setIsConnectionOpen(false);
  }, []);

  useEffect(() => {
    if (isWidget) {
      initWidgetChatPanel();
    } else {
      initStandaloneChatPanel();
    }
    setBlockInput(threadId, true);
  }, [threadId]);

  const initStandaloneChatPanel = () => {
    if (!threadId) {
      setIsConnectionOpen(false);
      initializeWebSocket();
    } else {
      if (startsWithPrompt(threadId)) {
        const prompt = extractPromptContent(threadId);
        setRequestExternalPrompt(prompt);

        setIsConnectionOpen(false);
        initializeWebSocket();
      } else {
        if (socketServiceRef.current) {
          socketServiceRef.current.closeForGood();
          socketServiceRef.current = null;
        }
        socketServiceRef.current = new SocketService({
          threadId: threadId,
          onMessageCallback: handleMessage,
          onErrorCallback: handleError,
          onConnectionOpen: onConnectionOpen,
        });
        //** store ref for activity panel usage */
        setSocketServiceRef(socketServiceRef.current);
      }
    }
  };

  const initWidgetChatPanel = () => {
    if (socketServiceRef.current) {
      socketServiceRef.current.closeForGood();
      socketServiceRef.current = null;
    }
    socketServiceRef.current = new SocketService({
      proposalId: proposalId,
      socketMode: "widget",
      onMessageCallback: handleMessage,
      onErrorCallback: handleError,
      onConnectionOpen: onConnectionOpen,
    });
    //** store ref for activity panel usage */
    setSocketServiceRef(socketServiceRef.current);
  };

  const initializeWebSocket = async (threadId: string | null = null) => {
    AssistantService.newThread().then((thread) => {
      navigate(`/assistant/${thread.id}`);
    });
  };
  useEffect(() => {
    return () => {
      if (socketServiceRef.current) {
        socketServiceRef.current.closeForGood();
        socketServiceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (status === AI_PRO_ADVISOR.COMPLETED) {
      resetAction(threadId);
    }
  }, [status, threadId]);

  const handleMessage = (message: any) => {
    //********************** */
    switch (message.type) {
      //****************
      //** SET STATUS
      //****************
      case AI_PRO_ADVISOR.CONFIGURE:
        setSessionConfig(message.payload.config);
        break;
      case AI_PRO_ADVISOR.DISPLAY_ACTION:
        switch (message.payload.action.type) {
          case AI_PRO_ADVISOR.COLLECT_ESG_PREFERENCES:
            useThreadsStore.getState().appendEsgPreferences(threadId, {
              created_at: new Date().toISOString(),
              questions: EsgFormData,
              object: "esg_preferences_form",
              actionId: message.payload.action.id,
              actionThreadId: message.payload.action.thread_id,
            });
            break;
          case AI_PRO_ADVISOR.ANALYZE_PORTFOLIO_FROM_WEIGHTS:
          case AI_PRO_ADVISOR.SET_PRODUCTS:
            appendAction(threadId, {
              ...message.payload,
              object: message.payload.action.type,
            });
            break;
          default:
            break;
        }
        break;
      case AI_PRO_ADVISOR.SET_STATUS:
        const status = message.payload.status;
        setTaskUpdate(undefined);
        //** STATUS SWITCH */
        setStatus(status);
        switch (status) {
          case AI_PRO_ADVISOR.IN_PROGRESS:
            //block input true
            // setInputPlaceholder(parsePlaceholder(status, t))
            // setInputDisabled(true)
            setThinking(true);
            setBlockInput(threadId, true);
            setAgentStatus("working");

            break;
          case AI_PRO_ADVISOR.COMPLETED:
            //block input false
            setThinking(false);
            setBlockInput(threadId, false);
            setAgentStatus("idle");

            break;
          case AI_PRO_ADVISOR.EXPIRED:
          case AI_PRO_ADVISOR.CANCELLED:
          case AI_PRO_ADVISOR.FAILED:
            setAgentStatus("idle");
            // retry button
            // setInputPlaceholder(parsePlaceholder(status, t))
            // setStatusExpiredFailedCancelled(true)
            setThinking(false);
            setRetryButton(true);
            break;
          default:
            setAgentStatus("idle");
            setThinking(false);
        }
        break;
      //****************
      //** APPEND_MESSAGES
      //****************
      case AI_PRO_ADVISOR.APPEND_MESSAGES:
        if (message.payload.messages) {
          appendMessages(threadId, message.payload.messages);
        }
        fetchThread(threadId);
        fetchPortfolios(threadId);
        setRefreshProposalId(Math.random());
        break;
      //****************
      //** APPEND_MESSAGE_DELTA
      //****************
      case AI_PRO_ADVISOR.APPEND_MESSAGE_DELTA:
        if (message.payload.delta) {
          appendMessageDelta(threadId, message.payload.delta.content);
        }
        break;
      //****************
      //** APPEND_ATTACHMENTS
      //****************
      case AI_PRO_ADVISOR.APPEND_ATTACHMENTS:
        appendAttachments(threadId, message.payload.attachments);
        break;
      //****************
      //** SET_TASK_UPDATES
      //****************
      case AI_PRO_ADVISOR.SET_TASK_UPDATES:
        const taskUpdates = message.payload.updates || [];
        // get the latest task update
        if (taskUpdates.length > 0) {
          let latestTaskUpdate = taskUpdates[taskUpdates.length - 1];

          setTaskUpdate(latestTaskUpdate);
        } else {
          setTaskUpdate(undefined);
        }
        break;
      default:
    }
  };

  const handleError = (error: any) => {
    console.error("ERROR...... ******", error);
  };
  const onConnectionOpen = () => {
    setIsConnectionOpen(true);

    if (requestExternalPrompt) {
      sendUserMessage(requestExternalPrompt);
      setRequestExternalPrompt(null);
    }
  };

  const sendUserMessage = async (data: MessageData) => {
    setHasSentMessage(true);
    // let messageIndex: number | string = 0;
    // if (listContent[0]) {
    //   messageIndex = listContent[0].created_at + 1;
    // }
    // const messageToAppend: AiProAdvisorMessageType = createMessage(
    //   userMessage,
    //   messageIndex.toString(),
    //   "user",
    //   Math.random().toString(),
    // );
    // setThreadMessages([messageToAppend]);

    //TODO when lost retry  cancel
    // //** fallback if websocket close for slow internet */
    // if (socketServiceRef.current && !socketServiceRef.current.isItOpen()) {
    //   userMessageRequestConnections(messageToAppend, userMessage);
    // }

    if (threadId) {
      if (socketServiceRef.current) {
        setMessageSent(true);
        // setHasUserScrolled(false);
        socketServiceRef.current.sendUserMessage(data);
      }
    } else {
      // userMessageRequestConnections(messageToAppend, userMessage);
    }
  };

  const [, setSessionConfig] = useState<AssistantSessionConfig>();
  const [retryButton, setRetryButton] = useState(false);
  const [taskUpdate, setTaskUpdate] = useState<AssistantTaskUpdate>();
  const { blockInput, setBlockInput, fetchThread, fetchPortfolios } =
    useThreadsStore();

  const messages = useThreadsStore((state) => state.messages[threadId]);
  const esgPreferences = useThreadsStore(
    (state) => state.esgPreferences[threadId],
  );

  const [messageSent, setMessageSent] = useState(false);

  // useEffect(() => {
  //   scrollToBottom("lastAdvisorMessage");
  //   setTimeout(() => {
  //     scrollToBottom("lastAdvisorMessage");
  //   }, 100);
  // }, [messages?.length, blockInput, esgPreferences]);

  return (
    <div
      className="flex h-full flex-col justify-end"
      style={{
        height: "calc(100% - 0px)",
        marginTop: 0,
      }}
    >
      {/* No connection. show loading */}
      {!isConnectionOpen && (
        <ChatWelcome
          sendUserMessage={sendUserMessage}
          isDisabled={blockInput[threadId]}
          isConnectionOpen={isConnectionOpen}
        />
      )}
      {/* Connection has been established */}
      {isConnectionOpen && (
        <>
          <>
            {/* no messages yet. coming from history */}
            {messages?.length === 0 && fromHistory && (
              <ChatWelcome
                sendUserMessage={sendUserMessage}
                isDisabled={blockInput[threadId]}
                isConnectionOpen={isConnectionOpen}
                isWidget={isWidget}
              />
            )}
            {!messages && (
              <ChatWelcome
                sendUserMessage={sendUserMessage}
                isDisabled={blockInput[threadId]}
                isConnectionOpen={isConnectionOpen}
                isWidget={isWidget}
              />
            )}
            {/* no messages yet. new chat */}
            {messages?.length === 0 && !fromHistory && (
              <ChatWelcome
                sendUserMessage={sendUserMessage}
                isDisabled={blockInput[threadId]}
                isConnectionOpen={isConnectionOpen}
                isWidget={isWidget}
              />
            )}
            {/* chat */}
            {messages?.length > 0 && (
              <ChatMain
                chatStatus={status}
                isConnectionOpen={isConnectionOpen}
                onSendUserMessage={sendUserMessage}
                socketService={socketServiceRef.current}
                retryButton={retryButton}
                messageSent={messageSent}
                thinking={thinking}
                taskUpdate={taskUpdate}
                onRetry={() => {
                  socketServiceRef.current?.retry();
                  setRetryButton(false);
                }}
                widgetThreadId={widgetThreadId}
                isWidget={isWidget}
              />
            )}
          </>
        </>
      )}
      <div
        className="mt-[10px] font-medium"
        style={{ marginLeft: 20, marginRight: 20, marginBottom: 0 }}
      >
        {/* <span
          className="text-sm italic text-gray-500"
          style={{ color: colorsConfig.finecoBlack }}
        >
          in partnership with
        </span>{" "}
        <span style={{ color: colorsConfig.finecoBluePrimary }}>
          Streetbeat
        </span> */}
      </div>
    </div>
  );
};
