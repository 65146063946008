export const AI_PRO_ADVISOR = {
  //** AiProAssistantSocketMessageType */
  SET_STATUS: "set_status",
  APPEND_MESSAGES: "append_messages",
  APPEND_MESSAGE_DELTA: "append_message_delta",
  APPEND_ATTACHMENTS: "append_attachments",
  SET_TASK_UPDATES: "set_task_updates",
  //
  CONFIGURE: "configure",
  DISPLAY_ACTION: "display_action",

  //** display action types */
  COLLECT_ESG_PREFERENCES: "collect_esg_preferences",
  ANALYZE_PORTFOLIO_FROM_WEIGHTS: "analyze_portfolio_from_weights",
  SET_PRODUCTS: "set_products",
  //
  //** AiProAdvisorThreadStatusModel */
  IN_PROGRESS: "in_progress",
  EXPIRED: "expired",
  FAILED: "failed",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};
