import { Textarea } from "shared/components/ui/textarea";
// import { SendIcon } from "lucide-react";
import { ChatDownArrowIcon } from "assets/svg/ChatDownArrowIcon";
import SendIcon from "assets/svg/SendIcon";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import activityPanelStore from "shared/store/activityPanelStore";
import colorsStore from "shared/store/colorsStore";
import { AssistantTaskUpdate } from "types/assistant/AssistantTypes";
import { Processing } from "./Processing";
import "./chat-input-styles.css";
import styles from "./chatInput.module.css";
import { MicrophoneButton } from "./speech-to-text/MicrophoneButton";

export type FileData = {
  name: string;
  mimeType: string;
  data: string;
}

export type MessageData = {
  text: string;
  file?: FileData;
};

type Props = {
  onSend?: (data: MessageData) => void;
  onCancel?: () => void;
  disabled?: boolean;
  showArrow?: boolean;
  arrowAction?: () => void;
  taskUpdate?: AssistantTaskUpdate;
};

export const ChatInput: React.FC<Props> = ({
  onSend,
  onCancel,
  disabled,
  showArrow,
  arrowAction,
  taskUpdate,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [recording, setRecording] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [file, setFile] = useState<FileData | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { setUserSentFirstMessage } = activityPanelStore();

  useEffect(() => {
    if (textAreaRef) {
      adjustHeight(textAreaRef.current);
    }
  }, [textAreaRef, message]);

  useEffect(() => {
    if (recording) {
      setMessage("");
    }
  }, [recording]);

  // Focus on the text area when disabled becomes false
  useEffect(() => {
    if (!disabled && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [disabled]);

  const handleSend = () => {
    if (message) {
      setUserSentFirstMessage(true);
      onSend?.({
        text: message,
        file: file || undefined,
      });
      setMessage("");
      setFile(null);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // To prevent adding a new line to the end of a message
      handleSend();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target?.result as string;
      const base64Data = base64.split(',')[1];
      
      // Determine MIME type
      let mimeType = file.type;
      if (file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (file.name.toLowerCase().endsWith('.png')) {
        mimeType = 'image/png';
      } else if (file.name.toLowerCase().endsWith('.pdf')) {
        mimeType = 'application/pdf';
      }

      setFile({
        name: file.name,
        data: base64Data,
        mimeType: mimeType,
      });
    };
    reader.readAsDataURL(file);
  };

  function adjustHeight(el: any) {
    if (!el) return;
    el.style.height =
      el.scrollHeight > el.clientHeight ? el.scrollHeight + "px" : "46px";
  }

  let copyPlaceHolder = t("assistant.inputPlaceholder");
  if (taskUpdate) {
    if (taskUpdate.update) {
      copyPlaceHolder = taskUpdate.update;
    }
  }
  if (recording) {
    copyPlaceHolder = "";
  }

  const useCustomPlaceholderStyle = true; // Replace this with your actual condition

  // Custom placeholder style if the condition is met
  const customPlaceholderStyle = `
    textarea::placeholder {
      color: #00549F;
      font-size: 14px;
      font-weight: 600;
    }
  `;

  return (
    <div className="relative" ref={containerRef}>
      {showArrow && (
        <div
          onClick={arrowAction}
          className="-translate-x-1/2"
          style={{
            cursor: "pointer",
            position: "absolute",
            left: "50%",
            top: -55,
            width: 40,
            height: 40,
            // backgroundColor: "white", // White background
            // border: "3px solid " + colorsConfig.finecoBluePrimary, // Pink border
            // borderRadius: "50%", // Makes it circular
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChatDownArrowIcon
            style={{ color: colorsConfig.finecoBluePrimary }}
            isExpanded={true}
          />
        </div>
      )}

      <div className="relative">
        <Textarea
          ref={textAreaRef}
          value={message}
          onKeyDownCapture={handleKeyDown}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={copyPlaceHolder}
          className={styles.chatTextInput}
          style={{
            backgroundColor: colorsConfig.white,
            color: colorsConfig.black,
            // width: "calc(100% - 58px)",
          }}
          // @ts-ignore
          placeholderstyle={taskUpdate ? customPlaceholderStyle : undefined}
          disabled={disabled}
        />

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".pdf,.jpg,.jpeg,.png"
          style={{ display: 'none' }}
        />

        {!taskUpdate && (
          <div 
            className="absolute right-4 flex items-center gap-2" 
            style={{ right: 40, bottom: 14 }}
          >
            {file?.name && (
              <span
                className="whitespace-nowrap"
                style={{ 
                  color: colorsConfig.black,
                  marginRight: '8px',
                  fontSize: '14px',
                  fontFamily: 'inherit',
                  fontWeight: 400,
                }}
              >
                {file.name}
              </span>
            )}
            <div
              onClick={() => !disabled && fileInputRef.current?.click()}
              className={!disabled ? "cursor-pointer duration-150 hover:opacity-50" : ""}
              style={{
                color: colorsConfig.chatInputColor,
                opacity: disabled ? 0.5 : 1,
              }}
            >
              <PdfAttachIcon />
            </div>
          </div>
        )}

        <div className="absolute right-4" style={{ right: 10, bottom: 14 }}>
          <div
            onClick={handleSend}
            className={
              message ? "cursor-pointer duration-150 hover:opacity-50" : ""
            }
          >
            {!taskUpdate && (
              <SendIcon
                style={{
                  color: colorsConfig.chatInputColor,
                  width: 18,
                  height: 18,
                }}
              />
            )}
            {taskUpdate && (
              <div
                onClick={onCancel}
                className={
                  message ? "cursor-pointer duration-150 hover:opacity-50" : ""
                }
              >
                <div className={styles.chatCancel}>
                  <CancelButton />
                </div>
              </div>
            )}
          </div>
        </div>

        {!taskUpdate && (
          <div className="absolute bottom-[14px] z-50" style={{ left: 10 }}>
            <MicrophoneButton
              onText={(t) => {
                onSend?.({text: t, file: undefined});
              }}
              onRecordingChanged={setRecording}
              disabled={disabled}
            />
          </div>
        )}
        {taskUpdate && (
          <div className="absolute bottom-[6px] z-50" style={{ left: 10 }}>
            <Processing />
          </div>
        )}
      </div>
    </div>
  );
};

const CancelButton = () => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      style={{
        width: "26px",
        height: "26px",
        border: "2px solid",
        borderColor: colorsConfig.finecoBluePrimary,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "12px",
          height: "12px",
          backgroundColor: colorsConfig.finecoBluePrimary,
        }}
      />
    </div>
  );
};

const PdfAttachIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
  </svg>
);