// import { ApiRoutes } from "@/Utils/routes";
import { AssistantRoutes } from "config/routes/AssistantRoutes";
import { MessageData } from "pages/assistant/components/chat/components/ChatInput";
import { auth, environment } from "shared/utils/firebase";
import { FaApiRoutes } from "shared/utils/routes";

// const STAGING_WSS_URL = 'wss://api.staging.streetbeat.com'
// const PROD_WSS_URL = 'wss://api.streetbeat.com'

const STAGING_WSS_URL = "wss://api.staging.streetbeat.com";
const PROD_WSS_URL = "wss://api.streetbeat.com";

type SocketModeType = "widget" | "standalone";
export type SocketConfigType = {
  threadId?: string;
  proposalId?: string;
  onMessageCallback: (message: any) => void;
  onErrorCallback: (error: Event | Error) => void;
  onConnectionOpen: () => void;
  socketMode?: SocketModeType;
};
class SocketService {
  private sessionId: string;
  private proposalId: string;
  private socketMode: SocketModeType;
  private reconnectInterval: number;
  private maxReconnectInterval: number;
  private ws: WebSocket | null = null;
  private shouldReconnect: boolean = true;
  private isOpen: boolean;
  private onMessageCallback: (message: any) => void;
  private onConnectionOpen: () => void;
  private onErrorCallback: (error: Event | Error) => void;
  private reconnectTimeoutId: ReturnType<typeof setTimeout> | null = null;

  constructor(
    // sessionId: string,
    // onMessageCallback: (message: any) => void,
    // onErrorCallback: (error: Event | Error) => void,
    // onConnectionOpen: () => void,
    socketConfig: SocketConfigType,
  ) {
    this.socketMode = socketConfig.socketMode
      ? socketConfig.socketMode
      : "standalone";
    this.proposalId = socketConfig.proposalId;
    this.sessionId = socketConfig.threadId;
    this.reconnectInterval = 1000;
    this.maxReconnectInterval = 30000;
    this.onMessageCallback = socketConfig.onMessageCallback;
    this.onErrorCallback = socketConfig.onErrorCallback;
    this.initiateConnection();
    this.onConnectionOpen = socketConfig.onConnectionOpen;
    this.isOpen = false;
  }

  public sendUserMessage(data: MessageData): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      if (data.file) {
        const content = [
          {
            type: "document",
            source: {
              type: "base64",
              media_type: data.file.mimeType,
              data: data.file.data,
            },
          },
          { type: "text", text: { value: data.text } },
        ];
        this.ws.send(
          JSON.stringify({
            type: "add_message",
            payload: { content: content },
          }),
        );
      } else {
        this.ws.send(
          JSON.stringify({
            type: "add_message",
            payload: { content: data.text },
          }),
        );
      }
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }
  public cancel(): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ type: "cancel" }));
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }
  public retry(): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ type: "retry" }));
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }
  public setVectorStores(vectorStoreIds: string[]): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: "set_vector_stores",
          payload: {
            vector_store_ids: vectorStoreIds,
          },
        }),
      );
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }
  public sendConstraints(constraintsData: any): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: "set_custom_data",
          payload: {
            data: constraintsData,
            version: "fineco_portfolio_constraints",
          },
        }),
      );

      // send a command message to the assistant, it will be hidden from the user
      this.ws.send(
        JSON.stringify({
          type: "add_message",
          payload: {
            content:
              "Sono stati impostati dei vincoli. Crea un nuovo portafoglio adesso.",
            hidden: false,
          },
        }),
      );
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }
  public sendEsgPreferences(esgPreferences: any): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: "set_custom_data",
          payload: {
            data: esgPreferences,
            version: "fineco_esg_preferences",
          },
        }),
      );
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }
  public isItOpen(): boolean {
    return this.isOpen;
  }
  public sendPortfolio(portfolioId: string): void {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: "set_portfolio",
          payload: { portfolio_id: portfolioId },
        }),
      );
    } else {
      this.isOpen = false;
      console.error("WebSocket is not open.");
    }
  }

  //** INITIATE CONNECTION */
  private async initiateConnection(): Promise<void> {
    try {
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        this.connect(token);
      } else {
        throw new Error("Failed to retrieve token");
      }
    } catch (error) {
      console.error("Initialization Error:", error);
      if (error instanceof Error) {
        this.onErrorCallback(error);
      } else {
        // If it's not an Error instance, you can wrap it in an Error object or handle it as you see fit
        this.onErrorCallback(
          new Error(
            "An unknown error occurred during WebSocket initialization",
          ),
        );
      }
      // Optionally, schedule a reconnect attempt or handle this case as needed
    }
  }

  //** WS CONNECT */
  private async connect(token: string) {
    let webSocketUrl: string;
    if (this.socketMode !== "widget") {
      webSocketUrl = `${AssistantRoutes.WS_THREADS}/${this.sessionId}`;
    } else {
      webSocketUrl = FaApiRoutes.Advisor.Proposals.Details(this.proposalId)
        .Assistant.Path;
    }
    // const webSocketUrl: string = `${AssistantRoutes.WS_THREADS}/${this.sessionId}`;
    // const isStaging = AuthUtils.environment() === "staging";
    // const baseUrl = isStaging ? STAGING_WSS_URL : PROD_WSS_URL;
    const env = await environment();
    const baseUrl = env === "staging" ? STAGING_WSS_URL : PROD_WSS_URL;
    const websocket = new WebSocket(`${baseUrl}${webSocketUrl}`);
    this.ws = websocket;

    this.ws.onopen = () => {
      this.isOpen = true;
      console.info("WebSocket Connected");

      this.reconnectInterval = 1000; // Reset reconnect interval on successful connection
      websocket.send(
        JSON.stringify({
          type: "authenticate",
          payload: {
            token: token,
          },
        }),
      );
      this.onConnectionOpen();
    };

    this.ws.onmessage = (e) => {
      this.onMessageCallback(JSON.parse(e.data));
    };

    this.ws.onerror = (e) => {
      console.error("WebSocket Error:", e);
      this.onErrorCallback(e);
    };

    this.ws.onclose = (e) => {
      this.isOpen = false;
      console.info("WebSocket Disconnected", e.reason);
      console.info("this.shouldReconnect", this.shouldReconnect);
      if (this.shouldReconnect) {
        this.initiateConnection(); // Modify to re-initiate the entire connection process, including token retrieval
      }
    };
  }

  private scheduleReconnect(): void {
    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId);
    }

    this.reconnectTimeoutId = setTimeout(() => {
      console.info("Attempting to reconnect...");
      this.initiateConnection();
    }, this.reconnectInterval);

    // Exponential backoff
    this.reconnectInterval = Math.min(
      this.reconnectInterval * 2,
      this.maxReconnectInterval,
    );
  }

  public closeForGood(): void {
    this.shouldReconnect = false;
    if (this.ws) {
      this.ws.close();
    }
  }
}
export default SocketService;
