import { FinecoAgentIcon } from "assets/svg/FinecoAgentIcon";
import { useTranslation } from "react-i18next";
import brandStore from "shared/store/brandStore";
import colorsStore from "shared/store/colorsStore";
import { ChatInput, MessageData } from "./ChatInput";
import StreetbeatIcon from "./StreetbeatIcon";
import { SuggestedPrompts } from "./SuggestedPrompts";

type AssistantWelcomeProps = {
  sendUserMessage: (data: MessageData) => void;
  isDisabled?: boolean;
  isConnectionOpen: boolean;
  isWidget?: boolean;
};

export const ChatWelcome: React.FC<AssistantWelcomeProps> = ({
  sendUserMessage,
  isDisabled,
  isConnectionOpen,
  isWidget = false,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const aiChatLogo = brandStore((state) => state.aiChatLogo);
  const { t } = useTranslation();
  const iconSizeClass = `icon-size-32-32`;

  return (
    <>
      <div className="flex-grow">
        <div className="grid h-full place-items-center">
          <div className="text-center">
            <div className="animated-div mb-4 flex items-center justify-center gap-4">
              {aiChatLogo === "fineco" && (
                <FinecoAgentIcon className={iconSizeClass} />
              )}
              {aiChatLogo === "streetbeat" && <StreetbeatIcon size={40} />}

              <h1
                style={{
                  color: colorsConfig.black,
                  fontSize: 24,
                  marginTop: 5,
                }}
              >
                {isConnectionOpen
                  ? t("assistant.howCanIHelp")
                  : t("assistant.connecting")}
              </h1>
            </div>
            {isConnectionOpen && !isWidget && (
              <SuggestedPrompts onSend={(text) => sendUserMessage({text: text, file: undefined})} />
            )}
          </div>
        </div>
      </div>
      {isConnectionOpen && (
        <>
          <div
            style={{ position: "relative", marginLeft: 20, marginRight: 20 }}
          >
            <ChatInput
              onSend={(data) => sendUserMessage(data)}
              disabled={isDisabled}
            />
          </div>
        </>
      )}
    </>
  );
};
