import { Portfolio } from "../models/portfolio/PortfolioModel";
import {
  ProAssistantAttachment,
  ProAssistantMessage,
  ProAssistantThread,
  ProAssistantThreadHistoryItem,
} from "../models/pro_assistant/ProAssistantModel";
import { ProAssistantService } from "../services/pro_assistant/ProAssistantService";
import { Loading } from "../types/enums";
import { create } from "./store";

type ProAssistantStoreState = {
  threads: ProAssistantThreadHistoryItem[];
  attachments: Record<string, ProAssistantAttachment[]>;
  messages: Record<string, ProAssistantMessage[]>;
  portfolios: Record<string, Portfolio[]>;
  threadDetails: Record<string, ProAssistantThread>;
  blockInput: Record<string, boolean>;

  historyLoading: Loading;
};

type ProAssistantStoreActions = {
  fetchThreads: () => void;
  appendAttachments: (
    threadId: string,
    attachments: ProAssistantAttachment[],
  ) => void;
  appendMessages: (threadId: string, messages: ProAssistantMessage[]) => void;
  appendMessageDelta: (threadId: string, messageDelta: string) => void;
  fetchPortfolios: (threadId: string) => void;
  fetchThread: (threadId: string) => void;
  setBlockInput: (threadId: string, blockInput: boolean) => void;
  resetThread: (threadId: string) => void;
};

const initialData: ProAssistantStoreState = {
  threads: [],
  attachments: {},
  portfolios: {},
  messages: {},
  threadDetails: {},
  blockInput: {},
  historyLoading: Loading.Idle,
};

const useProAssistantStore = create<
  ProAssistantStoreState & ProAssistantStoreActions
>()((set, get) => ({
  ...initialData,
  fetchThreads: async () => {
    set({ historyLoading: Loading.InProgress });
    try {
      const threads = await ProAssistantService.fetchThreads();
      set({ threads });
    } finally {
      set({ historyLoading: Loading.Finished });
    }
  },
  fetchThread: async (threadId) => {
    const thread = await ProAssistantService.fetchThread(threadId);
    set({ threadDetails: { ...get().threadDetails, [threadId]: thread } });
  },
  appendAttachments: async (threadId, attachments) => {
    const attachmentIds = get().attachments[threadId]?.map(
      (m) => m.attachment_id,
    );
    const deduplicatedAttachments =
      attachments.filter(
        (m) =>
          !attachmentIds?.includes(m.attachment_id) && m.thread_id === threadId,
      ) || [];

    set({
      attachments: {
        ...get().attachments,
        [threadId]: [
          ...(deduplicatedAttachments || []),
          ...(get().attachments[threadId] || []),
        ],
      },
    });
  },
  appendMessages: async (threadId, messages) => {
    const currentMessages = get().messages[threadId] || [];
    const messageIds = currentMessages.map((m) => m.id);

    const lastMessage = currentMessages[currentMessages.length - 1];
    const isLastMessageTemp = lastMessage && lastMessage.id === "temp";

    const messagesWithoutTemp = isLastMessageTemp
      ? currentMessages.slice(0, currentMessages.length - 1)
      : currentMessages;

    const deduplicatedMessages = messages.filter(
      (m) => !messageIds.includes(m.id) && m.thread_id === threadId,
    );

    set({
      messages: {
        ...get().messages,
        [threadId]: [...messagesWithoutTemp, ...(deduplicatedMessages || [])],
      },
    });
  },
  appendMessageDelta: async (threadId, messageDelta) => {
    const currentMessages = get().messages[threadId] || [];

    if (
      currentMessages.length > 0 &&
      currentMessages[currentMessages.length - 1].id === "temp"
    ) {
      const updatedMessages = currentMessages.map((msg, index) => {
        if (index === currentMessages.length - 1) {
          const updatedContent = [...msg.content];
          const existingTextValue = updatedContent[0]?.text?.value ?? "";
          updatedContent[0] = {
            ...updatedContent[0],
            text: {
              ...updatedContent[0].text,
              value: existingTextValue + messageDelta,
            },
          };

          return {
            ...msg,
            content: updatedContent,
          };
        }
        return msg;
      });

      set({
        messages: {
          ...get().messages,
          [threadId]: updatedMessages,
        },
      });
    } else {
      const currentTime = new Date().toISOString();
      const newMessage = {
        id: "temp",
        thread_id: threadId,
        role: "assistant" as const,
        artifacts: [],
        status: "completed" as const,
        created_at: currentTime,
        updated_at: currentTime,
        metadata: {},
        content: [
          {
            type: "text" as const,
            text: {
              value: messageDelta,
            },
          },
        ],
      };

      set({
        messages: {
          ...get().messages,
          [threadId]: [...currentMessages, newMessage],
        },
      });
    }
  },
  fetchPortfolios: async (threadId) => {
    const portfolios = await ProAssistantService.fetchPortfolios(threadId);
    set({
      portfolios: {
        ...get().portfolios,
        [threadId]: portfolios,
      },
    });
  },
  setBlockInput: async (threadId, blockInput) => {
    set({
      blockInput: {
        ...get().blockInput,
        [threadId]: blockInput,
      },
    });
  },
  resetThread: (threadId) => {
    set({
      messages: {
        ...get().messages,
        [threadId]: [],
      },
      attachments: { ...get().attachments, [threadId]: [] },
      blockInput: { ...get().blockInput, [threadId]: false },
    });
  },
}));

export default useProAssistantStore;
