import { useEffect, useState } from "react";
import { get, ref, onValue } from "firebase/database";
import { database } from "shared/utils/firebase";
import { BrandType } from "types/BrandTypes";
import { getEnvironment } from "./UpdateBrandPage";


export const getBrandName = async () => {
  const isProd = window.location.href.includes("eviva.streetbeat.com");
  
  if (isProd) {
    const url = window.location.href;
    const match = url.match(/^(?:https?:\/\/)?([^.]+)\.eviva\.streetbeat\.com/);
    if (match) {
      // For any specific brand subdomain except demo
      if (match[1] !== "demo") {
        return match[1];
      }
    } else {
      // For main domain eviva.streetbeat.com
      return "eviva";
    }
  }

  // For demo subdomain or non-prod environments, get from Firebase
  const env = getEnvironment();
  const brandNameRef = ref(database(), `${env.dbName}/brandName`);
  const snapshot = await get(brandNameRef);
  return snapshot.val();
};

export const getBrandByName = async (brandName: string) => {
  if (!brandName) return null;

  const env = getEnvironment();
  const brandsRef = ref(database(), `${env.dbName}/brands`);
  const snapshot = await get(brandsRef);
  const brands: BrandType[] = snapshot.val() || [];
  return brands.find((brand) => brand.name === brandName);
};

export const useCurrentBrand = () => {
  const [currentBrand, setCurrentBrand] = useState<
    BrandType | { name: ""; image: ""; imageType: "" }
  >({ name: "", image: "", imageType: "" });
  const [brandName, setBrandName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const brandName = await getBrandName();
        if (!brandName) {
          setCurrentBrand(null);
          setBrandName("");
          return;
        }

        const brand = await getBrandByName(brandName);
        if (brand) {
          setCurrentBrand(brand);
          setBrandName(brand.name);
        } else {
          setCurrentBrand(null);
        }
      } catch (err) {
        // console.error("Error fetching brand data:", err);
        setError("Error fetching brand data");
      } finally {
        setLoading(false);
      }
    };

    fetchBrand();
  }, []);

  return { currentBrand, brandName, loading, error };
};
